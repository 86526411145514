<!--
 * @Author: Liu Hui
 * @Description: 客户标准销售价
-->
<template>
  <scroll-layout>
    <template slot="header">
      <div v-if="pageButtons.length > 0" class="button-wrap">
        <ykc-button
          v-for="(button, index) in pageButtons"
          :key="button.id || index"
          v-bind="button"
          @click="button.handleClick(button, $event)">
          {{ button.text }}
        </ykc-button>
      </div>
    </template>
    <div class="fleet-match-content">
      <YkcDetailPlus class="box-card" :labelWidth="150">
        <div slot="header">
          <span>基础资料</span>
        </div>
        <div class="baseInfo">
          <div class="header">
            <div class="head">
              <img v-if="detail?.imageUrl" :src="detail.imageUrl" alt="" class="" />
              <img v-else src="@/assets/images/card-no-img.png" alt="暂无图片" class="" />
              <div class="level" :class="[`level${detail.level}`]">{{ detail.levelName }}</div>
            </div>
          </div>
          <div class="content">
            <div class="top">
              <div>
                <span class="label">会员姓名:</span>
                <span class="value">
                  {{ dealData(detail.memberName) }}
                </span>
              </div>
              <div>
                <span class="label">会员手机:</span>
                <span class="value">
                  {{ dealData(detail.phone) }}
                </span>
              </div>
              <div>
                <span class="label">会员编码:</span>
                <span class="value">
                  {{ dealData(detail.memberNo) }}
                </span>
              </div>
              <div>
                <span class="label">会员账号:</span>
                <span class="value">
                  {{ dealData(detail.memberAccount) }}
                </span>
              </div>
              <div>
                <span class="label">会员性别:</span>
                <span class="value">
                  {{ dealData(detail.sex) }}
                </span>
              </div>
              <div>
                <span class="label">会员年龄:</span>
                <span class="value">
                  {{ dealData(detail.age) }}
                </span>
              </div>
              <div>
                <span class="label">归属客户:</span>
                <span class="value">
                  {{ dealData(detail.orgName) }}
                </span>
              </div>
              <div>
                <span class="label">城市:</span>
                <span class="value">
                  {{ getCityNameByCityId() }}
                </span>
              </div>
              <div>
                <span class="label">注册渠道:</span>
                <span class="value">
                  {{ dealData(detail.registerChannel) }}
                </span>
              </div>
              <div>
                <span class="label">注册时间:</span>
                <span class="value">
                  {{ dealData(detail.registerTime) }}
                </span>
              </div>
              <div>
                <span class="label">最近登录时间:</span>
                <span class="value">
                  {{ dealData(detail.lastLoginTime) }}
                </span>
                <!-- s -->
              </div>
              <div>
                <span class="label">最近充电时间:</span>
                <span class="value">
                  {{ dealData(detail.lastChargeTime) }}
                </span>
              </div>
            </div>
            <div
              style="width: 85%; border-top: 1px solid #f6f7f8; margin-top: 8px; padding-top: 8px"
              v-if="Array.isArray(detail.groupList)">
              <span>用户分组</span>
            </div>
            <div class="user-group" style="width: 100%; margin-top: 8px">
              <ykc-button
                v-for="item in detail.groupList"
                :key="item.id"
                type="plain"
                style="margin-right: 8px">
                {{ item.name }}
              </ykc-button>
            </div>
          </div>
        </div>
      </YkcDetailPlus>
      <YkcDetailPlus class="box-card" :labelWidth="150">
        <div slot="header" class="clearfix">
          <span>会员信息</span>
        </div>
        <div class="vipInfo">
          <div class="item">
            <div class="title">
              累计积分
              <div
                v-rbac="'operation:member:list:detail:integralDetail'"
                class="tRight"
                @click="handleIntegralDetail">
                明细
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
            <div class="itembg bg1"></div>
            <span class="value">{{ detail.points }}</span>
            <span
              v-rbac="'operation:member:list:editintegral'"
              class="link"
              @click="openAdjustIntegralDialog">
              调整积分
            </span>
          </div>
          <div class="item">
            <div class="title">
              累计成长值
              <div
                v-rbac="'operation:member:list:detail:growthDetail'"
                class="tRight"
                @click="handleGrowthValueDetail">
                明细
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
            <div class="itembg bg2"></div>
            <span class="value">{{ detail.growthValue }}</span>
            <span
              v-rbac="'operation:member:list:editgrowth'"
              class="link"
              @click="openAdjustGrowthValueDialog">
              调整成长值
            </span>
          </div>
          <div class="item">
            <div class="title">
              会员等级
              <div
                v-rbac="'operation:member:list:detail:levelDetail'"
                class="tRight"
                @click="showDialog('level')">
                查看
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
            <div class="itembg bg3"></div>
            <span class="value">{{ detail.levelName }}</span>
            <span class="link"></span>
          </div>
        </div>
      </YkcDetailPlus>
      <YkcDetailPlus class="box-card" :labelWidth="150">
        <div slot="header" class="clearfix">
          <span>账户信息</span>
        </div>
        <div class="userInfo">
          <div class="item">
            <div class="title">
              账户余额(元)
              <div
                class="tRight"
                v-rbac="'customer:customer:finance'"
                @click="handleAccountMoneyDetail">
                查看
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
            <div class="itembg bg4"></div>
            <span class="value">{{ detail.balance }}</span>
          </div>
          <div class="item">
            <div class="title">
              可用优惠券(张)
              <div
                v-rbac="'operation:member:list:detail:couponDetail'"
                class="tRight"
                @click="showDialog('coupon')">
                查看
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
            <div class="itembg bg5"></div>
            <span class="value">{{ detail.couponCount }}</span>
          </div>
          <div class="item">
            <div class="title">
              可用抵用卡(张)
              <div
                v-rbac="'operation:member:list:detail:cardDetail'"
                class="tRight"
                @click="showDialog('card')">
                查看
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
            <div class="itembg bg6"></div>
            <span class="value">{{ detail.cardCount }}</span>
          </div>
        </div>
      </YkcDetailPlus>
    </div>
    <YkcOperationDialog
      :title="dialogTitle"
      :before-close="onDialogClose"
      :showFooter="!['level', 'coupon', 'card'].includes(dialogType)"
      :show.sync="showDetail"
      :before-cancel="beforeCancel">
      <div slot="content">
        <!-- 会员等级 -->
        <div class="levelInfo" v-if="dialogType === 'level'">
          <div class="card" :class="[`vip${detail.level}`]">
            <span class="title">{{ vipLevelInfo.levelName }}</span>
            <div class="text" v-if="vipLevelInfo.curLevelMaxGrowth">
              <span>{{ detail.growthValue }}</span>
              /{{ vipLevelInfo.curLevelMaxGrowth }} 成长值 距离升级还需{{
                Number(vipLevelInfo.curLevelMaxGrowth) - Number(detail.growthValue)
              }}成长值
            </div>
            <div class="text" v-else>
              <span>{{ detail.growthValue }}/成长值</span>
              已达到最高等级
            </div>
            <div class="progress">
              <div
                class="valueLine"
                :style="{
                  maxWidth: '100%',
                  width: `${
                    (Number(detail.growthValue) / Number(vipLevelInfo.curLevelMaxGrowth)) * 100
                  }%`,
                }"></div>
            </div>
          </div>
          <div class="quanyi">
            <div class="title">会员权益</div>
            <div class="_item">
              <img src="@/assets/images/item1.png" alt="" />
              <div>
                <div class="name">会员折扣</div>
                <div class="detail">
                  {{ vipLevelInfo.discountTypeDesc }}{{ vipLevelInfo.discountRatioDesc }}
                </div>
              </div>
            </div>
            <div class="_item">
              <img src="@/assets/images/item2.png" alt="" />
              <div>
                <div class="name">升级礼包</div>
                <div class="detail">{{ vipLevelInfo.giftPackageName }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 优惠券 -->
        <div class="coupon" v-if="dialogType === 'coupon'">
          <template v-if="couponList.length > 0">
            <div
              class="_item"
              style="padding: 10px 0"
              v-for="(item, index) in couponList"
              :key="index">
              <div class="left">
                <div class="money">
                  ￥
                  <span>{{ item.faceValue }}</span>
                </div>
                <div class="param">充电满</div>
                <div class="param" style="margin-top: 2px">{{ item.amountLimit }}元可用</div>
              </div>
              <div class="right">
                <div class="title">{{ item.cardCouponName }}</div>
                <div class="info">适用电站：全平台</div>
                <div class="info card-expire-time">
                  <div>有效期至：{{ item.endTime.split(' ')[0] }}</div>
                  <div v-if="item.willExpireFlag === true" class="expire-tag">即将到期</div>
                </div>
              </div>
            </div>
          </template>
          <div v-else class="vip-detail-dialog-empty">
            <div class="img" :style="{ backgroundImage: `url(${NoDataImage})` }"></div>
            <div class="text">暂无数据</div>
          </div>
        </div>
        <!-- 抵用卡 -->
        <div class="card" v-if="dialogType === 'card'">
          <template v-if="cardList.length > 0">
            <div
              class="_item"
              style="padding: 10px 0"
              v-for="(item, index) in cardList"
              :key="index">
              <div class="left"></div>
              <div class="right">
                <div class="title">{{ item.cardCouponName }}</div>
                <div class="info">剩余额度：{{ item.availableAmount }} 元</div>
                <div class="info">有效期至：{{ item.endTime.split(' ')[0] }}</div>
              </div>
            </div>
          </template>
          <div v-else class="vip-detail-dialog-empty">
            <div class="img" :style="{ backgroundImage: `url(${NoDataImage})` }"></div>
            <div class="text">暂无数据</div>
          </div>
        </div>
      </div>
    </YkcOperationDialog>
  </scroll-layout>
</template>

<script>
  import { marketManage, treeUtils } from '@/service/apis';
  import { getListNameById, dealData } from '@/utils/index';
  import openAdjustDialog from './dialog';
  import { localGetItem } from '@/utils';
  import NoDataImage from '@/components/ykc-ui/assets/no-data.png';

  export default {
    name: 'SellingPriceDetail',
    data() {
      const { member_level = [] } = localGetItem('dictionary');
      return {
        NoDataImage,
        districts: [],
        cardList: [],
        couponList: [],
        vipLevelInfo: {},
        showDetail: false,
        dialogType: '',
        fleetTypeNew: [],
        orgType: '', // 车队类型的值
        settingType: '', // 是否基础
        vipLevelList: member_level.map(({ code, name }) => ({ id: code, name })),
        dialogInfo: {},
        detail: {
          memberName: '',
          city: '',
          orgName: '',
          age: '',
          sex: '',
          memberAccount: '',
          memberNo: '',
          phone: '',
          lastChargeTime: '',
          lastLoginTime: '',
          registerTime: '',
          registerChannel: '',
          imageUrl: '',
          balance: '',
          couponIds: ['string'],
          couponCount: '',
          cardIds: ['string'],
          cardCount: '',
          level: '',
          points: '',
          growthValue: '',
          levelName: '',
          remark: '',
          groupList: [
            // {
            //   name: 'name',
            //   id: 'id',
            // },
          ],
        },
        baseDiscountList: [],
        dictionary: {},
        priceId: '',
        pageButtons: [
          {
            id: '1',
            text: '返回',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ],
      };
    },
    computed: {
      dialogTitle() {
        if (this.dialogType === 'level') {
          return '会员等级详情';
        }
        if (this.dialogType === 'coupon') {
          return '可用优惠券';
        }
        if (this.dialogType === 'card') {
          return '可用抵用卡';
        }
        return '';
      },
    },
    created() {
      const { priceId } = this.$route.query;
      this.priceId = priceId;
      console.log('this.$route', this.$route);
      this.getDetail();
      this.getLevelInfo();
      this.getCardCouponInfo();
      this.getDistrict();
      this.$store
        .dispatch('getDictionaryByKeys', ['FLEET_TYPE', 'PREFERENTIAL_TYPE', 'CONFIGURE_ATTRIBUTE'])
        .then(res => {
          this.dictionary = res;
          console.log('this.dictionary---', this.dictionary);
        });
    },
    methods: {
      dealData,
      getListNameById,
      getCityNameByCityId() {
        let provinceName = '';
        let cityName = '';
        if (this.detail && this.detail.provinceId) {
          const provinceItem = this.districts.find(
            item => String(item.id) === String(this.detail.provinceId)
          );
          if (provinceItem) {
            provinceName = provinceItem.name;
          }
        }
        if (this.detail && this.detail.cityId) {
          const cityItem = this.districts.find(
            item => String(item.id) === String(this.detail.cityId)
          );
          if (cityItem) {
            cityName = cityItem.name;
          }
        }
        if (!provinceName && !cityName) return '————';
        return `${provinceName} ${cityName}`;
      },
      getDistrict() {
        treeUtils.getDistrict({ level: 3 }).then(({ districts }) => {
          const data = districts || [];
          const items = [];
          for (let i = 0; i < data.length; i++) {
            items.push(data[i]);
            if (Array.isArray(data[i].subNodes)) {
              data.push(...data[i].subNodes);
            }
          }
          console.log('districts', items);
          this.districts = items;
        });
      },
      showDialog(type) {
        this.dialogType = type;
        this.showDetail = true;
      },
      onDialogClose(...args) {
        console.log('onDialogClose', args);
      },
      beforeCancel(...args) {
        console.log('beforeCancel', args);
      },
      goUrl(url) {
        this.$router.push(url);
      },
      /**
       * 获取详情数据
       */
      getDetail() {
        const { id } = this.$route.query;
        marketManage.memberDetails({ userId: id }).then(response => {
          console.log('getDetail response', response);
          Object.assign(this.detail, response);
        });
      },
      getLevelInfo() {
        const { id } = this.$route.query;
        marketManage.findLevelInfoByUserId({ userId: id }).then(response => {
          console.log('findLevelInfoByUserId', response);
          this.vipLevelInfo = response;
        });
      },
      getCardCouponInfo() {
        const { id } = this.$route.query;
        Promise.all([
          marketManage.queryCardCouponByUserId({
            userId: id,
            // 抵用卡
            cardCouponType: '1',
            // 使用状态（0:未使用，1:使用中，2:已使用，3:已过期，4:已作废），默认是 ['0', '1']
            useStatus: ['0', '1'],
          }),
          marketManage.queryCardCouponByUserId({
            userId: id,
            // 优惠券
            cardCouponType: '2',
            // 使用状态（0:未使用，1:使用中，2:已使用，3:已过期，4:已作废），默认是 ['0', '1']
            useStatus: ['0', '1'],
          }),
        ]).then(([cards, coupons]) => {
          console.log('queryCardCouponByUserId', cards, coupons);
          this.cardList = cards.cardCouponDetailList;
          this.couponList = coupons.cardCouponDetailList;
        });
      },
      handleAccountMoneyDetail() {
        this.goUrl(
          `/financialManagement/accountManagement/cSideDriverAccountsDetail?id=${this.detail.userId}`
        );
      },
      handleIntegralDetail() {
        const { id } = this.$route.query;
        this.goUrl(
          `/operationCenter/vipManage/vipManage/integralFlow?id=${id}&userAccount=${this.detail.memberAccount}`
        );
      },
      handleGrowthValueDetail() {
        const { id } = this.$route.query;
        this.goUrl(
          `/operationCenter/vipManage/vipManage/growthValueFlow?id=${id}&userAccount=${this.detail.memberAccount}`
        );
      },
      openAdjustIntegralDialog() {
        openAdjustDialog(
          'integral',
          {
            growthValue: this.detail.growthValue,
            points: this.detail.points,
            userAccount: this.detail.memberAccount,
          },
          () => {
            this.getDetail();
            this.getLevelInfo();
          }
        );
      },
      openAdjustGrowthValueDialog() {
        openAdjustDialog(
          'growthValue',
          {
            growthValue: this.detail.growthValue,
            points: this.detail.points,
            userAccount: this.detail.memberAccount,
          },
          () => {
            this.getDetail();
            this.getLevelInfo();
          }
        );
      },
    },
  };
</script>
<style lang="scss" scoped>
  .fleet-match-content {
    padding: 10px;
    display: flex;
    flex-flow: column;
    .box-card {
      // width: 50%;
      margin: 5px 0;
      .baseInfo {
        display: flex;
        font-size: 14px;
        .header {
          width: 160px;
          display: flex;
          justify-content: center;
          .head {
            display: flex;
            flex-flow: column;
            justify-content: flex-start;
            img {
              width: 80px;
              height: 80px;
              border-radius: 80px;
            }
            .level {
              position: relative;
              height: 30px;
              width: 90px;
              border-radius: 10px;
              bottom: 25px;
              color: #fff;
              text-align: right;
              padding-right: 6px;
              line-height: 17px;
              font-weight: 700;
              font-style: italic;
              font-size: 13px;
              background-size: 100%;
              padding: 8px 8px 0 10px;
              box-sizing: border-box;
              left: -7px;
              background-repeat: no-repeat;
              &.level1 {
                background-image: url('~@/assets/images/vip1.png');
              }
              &.level2 {
                background-image: url('~@/assets/images/vip2.png');
              }
              &.level3 {
                background-image: url('~@/assets/images/vip3.png');
              }
              &.level4 {
                background-image: url('~@/assets/images/vip4.png');
              }
              &.level5 {
                background-image: url('~@/assets/images/vip5.png');
              }
              // &::
            }
          }
        }
        .content {
          flex-grow: 1;
          display: flex;
          flex-wrap: wrap;
          .label {
            color: #9b9b9b;
            font-size: 12px;
            margin-right: 10px;
          }
          .value {
            font-weight: 400;
            font-size: 12px;
          }
          .top {
            display: flex;
            flex-wrap: wrap;
            & > div {
              width: calc(100% / 3);
              height: 30px;
              line-height: 30px;
              display: flex;
              .label {
                color: #9b9b9b;
                font-size: 12px;
                margin-right: 10px;
              }
              .value {
                font-weight: 400;
                font-size: 12px;
              }
            }
          }
        }
        // 取消用户分组按钮hover等效果
        .user-group {
          .ykc-button.el-button--plain {
            cursor: default;
            &:hover {
              color: var(--theme-color-primary);
              background-color: transparent;
              border: 1px solid var(--theme-color-primary);
            }
          }
        }
      }
      .vipInfo {
        display: flex;
        .item {
          border: 1px solid #3773fc80;
          background-color: #3773fc1a;
          .tRight {
            background-color: #3773fc24;
            color: #4d8cfd;
            & > :last-child {
              margin-left: 4px;
            }
          }
        }
      }
      .userInfo {
        display: flex;
        .item {
          border: 1px solid #44d7b680;
          background-color: #44d7b61a;
          .tRight {
            background-color: #44d7b624;
            color: #02c498;
            & > :last-child {
              margin-left: 4px;
            }
          }
        }
      }

      .item {
        position: relative;
        width: calc((100% - 20px) / 3);
        border-radius: 10px;
        height: 188px;
        margin-right: 20px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        &:last-child {
          margin-right: 0px;
        }
        .title {
          position: absolute;
          width: 100%;
          font-size: 14px;
          font-weight: bold;
          top: 0;
          left: 0;
          padding: 20px;
          display: flex;
          justify-content: space-between;
          box-sizing: border-box;
          .tRight {
            width: 76px;
            height: 32px;
            display: flex;
            justify-content: center;
            border-radius: 12px;
            align-items: center;
            cursor: pointer;
          }
        }
        .value {
          font-weight: 600;
          font-size: 40px;
          height: 56px;
          line-height: 56px;
        }
        .link {
          color: #4d8cfd;
          font-size: 12px;
          height: 17px;
          line-height: 17px;
          cursor: pointer;
        }
      }
      .itembg {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 72px;
        height: 72px;
        background-repeat: no-repeat;
        background-size: 100%;
        &.bg1 {
          background-image: url(../../../../assets/images/itembg1.png);
        }
        &.bg2 {
          background-image: url(../../../../assets/images/itembg2.png);
        }
        &.bg3 {
          background-image: url(../../../../assets/images/itembg3.png);
        }
        &.bg4 {
          background-image: url(../../../../assets/images/itembg4.png);
        }
        &.bg5 {
          background-image: url(../../../../assets/images/itembg5.png);
        }
        &.bg6 {
          background-image: url(~@/assets/images/itembg6.png);
        }
      }
      .ellipsis {
        width: 500px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      table {
        width: 100%;
        border-left: 1px solid #ccc;
        border-top: 1px solid #ccc;
        th {
          height: 44px;
        }
        td {
          height: 40px;
        }
        th,
        td {
          border-bottom: 1px solid #ccc;
          border-right: 1px solid #ccc;
        }
      }
    }
  }
  ::v-deep .levelInfo {
    // height: 500px;
    .card {
      height: 160px;
      border-radius: 10px;
      background-repeat: no-repeat;
      background-size: 100%;
      padding: 30px;
      box-sizing: border-box;
      .title {
        font-size: 26px;
        font-family: YouSheBiaoTiHei;
        font-weight: bold;
        font-style: italic;
        // -webkit-background-clip: text;
        color: transparent;
        margin-bottom: 28px;
        display: inline-block;
      }
      .text {
        display: flex;
        align-items: center;
        font-size: 12px;
        margin-bottom: 9px;
        span {
          font-weight: bold;
          font-size: 20px;
          margin-right: 2px;
        }
      }
      .progress {
        background: rgba(255, 255, 255, 0.4);
        border-radius: 40px;
        height: 6px;
        .valueLine {
          width: 0px;
          height: 100%;
          border-radius: 40px;
        }
      }
      &.vip1 {
        background-image: url('~@/assets/images/vipbg1.png');
        .title {
          color: #1779fe;
          // background-image: linear-gradient(90deg, #1779fe 0%, #8dbdff 100%);
        }
        .text {
          color: #1779fe;
        }
        .progress .valueLine {
          background: linear-gradient(90deg, #9fc8ff 0%, #1779fe 100%);
        }
      }
      &.vip2 {
        background-image: url('~@/assets/images/vipbg2.png');
        .title {
          color: #415367;
          // background-image: linear-gradient(90deg, #415367 0%, #9fb4cc 100%);
        }
        .text {
          color: #415367;
        }
        .progress .valueLine {
          background: linear-gradient(90deg, #b6cee9 0%, #415367 100%);
        }
      }
      &.vip3 {
        background-image: url('~@/assets/images/vipbg3.png');
        .title {
          color: #b75500;
          // background-image: linear-gradient(90deg, #b75500 0%, #ff9c03 100%);
        }
        .text {
          color: #b75500;
        }
        .progress .valueLine {
          background: linear-gradient(90deg, #ff9c03 0%, #b75500 100%);
        }
      }
      &.vip4 {
        background-image: url('~@/assets/images/vipbg4.png');
        .title {
          color: #3623cf;
          // background-image: linear-gradient(90deg, #3623cf 0%, #947cff 100%);
        }
        .text {
          color: #3623cf;
        }
        .progress .valueLine {
          background: linear-gradient(90deg, #947cff 0%, #3623cf 100%);
        }
      }
      &.vip5 {
        background-image: url('~@/assets/images/vipbg5.png');
        .title {
          color: #f7de9c;
          // background-image: linear-gradient(90deg, #f7ca72 0%, #f7de9c 100%);
        }
        .text {
          color: #f7de9c;
        }
        .progress .valueLine {
          background: linear-gradient(90deg, #f7de9b 0%, #f7ca72 100%);
        }
      }
    }
    .quanyi {
      margin-top: 20px;
      border-radius: 30px;
      border: 1px solid #ebebf0;
      padding: 20px 30px;
      & > * {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
        &.title {
          font-size: 14px;
          font-weight: bold;
        }
        &._item {
          display: flex;
          img {
            width: 40px;
            height: 40px;
            margin-right: 20px;
          }
          & > div {
            flex-grow: 1;
            font-size: 12px;
            font-weight: 400;
            .name {
              color: #9b9b9b;
              margin-bottom: 6px;
            }
            .text {
              line-height: 17px;
            }
          }
        }
      }
    }
  }
  ::v-deep .coupon {
    ._item {
      display: flex;
      .left {
        width: 73px;
        height: 75px;
        background: url('~@/assets/images/coupon.png') no-repeat;
        background-size: 100%;
        .money {
          color: #90552f;
          font-size: 12px;
          margin-top: 6px;
          text-align: center;
          height: 23px;
          span {
            font-family: AlibabaPuHuiTi_2_85_Bold;
            font-size: 18px;
            font-weight: bold;
          }
        }
        .param {
          color: #f9f9f9;
          font-size: 10px;
          margin-top: 10px;
          padding: 0 2px;
          display: flex;
          text-align: center;
          justify-content: center;
          align-items: center;
        }
      }
      .right {
        flex-grow: 1;
        margin-left: 14px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        .title {
          font-size: 14px;
          font-weight: bold;
          margin-bottom: 8px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .info {
          margin-bottom: 5px;
          color: #9b9b9b;
          font-size: 12px;
          font-weight: 400;
          &.card-expire-time {
            display: flex;
            align-items: center;
            .expire-tag {
              margin-left: 8px;
              padding: 0px 6px;
              font-size: 10px;
              color: #e02020;
              border-radius: 8px;
              border: 1px solid #e02020;
            }
          }
        }
      }
    }
  }
  ::v-deep .card {
    ._item {
      display: flex;
      .left {
        width: 73px;
        height: 75px;
        background: url('~@/assets/images/card.png') no-repeat;
        background-size: 100%;
        .money {
          color: #90552f;
          font-size: 12px;
          margin-top: 6px;
          text-align: center;
          height: 23px;
          span {
            font-family: AlibabaPuHuiTi_2_85_Bold;
            font-size: 18px;
            font-weight: bold;
          }
        }
        .param {
          color: #f9f9f9;
          font-size: 10px;
          margin-top: 10px;
          padding: 0 2px;
          display: flex;
          text-align: center;
          justify-content: center;
          align-items: center;
        }
      }
      .right {
        flex-grow: 1;
        margin-left: 14px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        .title {
          font-size: 14px;
          font-weight: bold;
          margin-bottom: 8px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .info {
          margin-bottom: 5px;
          color: #9b9b9b;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
  }
</style>

<style lang="scss">
  .vip-detail {
    &-dialog-empty {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .img {
        width: 120px;
        height: 120px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: 0 0;
      }
    }
  }
</style>
